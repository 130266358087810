import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import LogoHeader from "../../assets/img/register/header_logo_2.png"
import UploadIcon from "../../assets/img/register/image.png"
import { history } from "../../utils";
import 'font-awesome/css/font-awesome.min.css';
import Faq from "../../components/faq/FloatingMenu";
import Sidebar from "../../components/layout/sidebar/index";
import NavbarIcon from "../../assets/img/register/navbar-icon.png"
import Gallery from '../../store/actions/gallery/index';
import Store from '../../store/actions/store/index';
import { Col } from "react-bootstrap";
import SearchButton from "../../components/buttons/SearchButton";
import { Button,Modal } from 'react-bootstrap'
import { white } from "material-ui/styles/colors";

import ListStore from "../../components/table/indexListStore";

const Index = ({action,data}) => {
    const [store, setStore] = useState([]);
    const [sidebar, setSidebar] = useState(false);

    const [filterId, setFilterId] = useState("");
    const [tempFilterId, setTempFilterId] = useState("");
    const [searchText, setSearchText] = useState("");
    const [tempSearchText, setTempSearchText] = useState("");
    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(null);
    const [maxPage, setMaxPage] = useState(1);
  
    const dispatch = useDispatch();

    useEffect(() => {
        handleRefresh();
        handleGetTypeStore();
    }, [page, searchText, filterId])
    const handleRefresh = () =>{
        
        new Promise((resolve)=>{
            let param = {
                page: page ? page : 1,
                length: 10,
                search_text: searchText,
                type_id: filterId  
            }
            dispatch(Store.getListStore(param, resolve))
        })
        .then(res => {
            setStore(res?.data.data)
            setTotalData(res?.data.records_total)
            setMaxPage(Math.ceil(res?.data.records_total / 10))
            
        })
    }

    const handleGetTypeStore = () => {
        new Promise((resolve) => {
            let param = {
                page: 1,
                length: 999
            }
            dispatch(Store.getTypeStore(param, resolve))
        })
        .then(res => {
            setListFilter(res.data.data)
        })
    }

    const handleSidebar = (value) => {
        if (value === false){
            console.log(value)
        }
       setSidebar(!sidebar)
    }
    
    const handleRef = () => {
        setSidebar(false)
    }
    
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    const [listFilter, setListFilter] = useState([
        {
            name:"Kios KTI",
            id: "1"
        }, 
        {
            name:"Prima Freshmart",
            id: "02bb63fb-ca70-4870-ad89-a64ceb8893aa"
        }, 
        {
            name:"Yamiku",
            id: "02bb63fb-ca70-4870-ad89-a64ceb8893ac"
        }, 
    ]);
    const [listStyleFilter, setListStyleFilter] = useState([]);
    const [backupListStyleFilter, setBackupListStyleFilter] = useState([]);

    const changeSearch = (data) => {
        setTempSearchText(data.target.value)
    }

    const handleSearch = () => {
        setPage(1)
        setSearchText(tempSearchText)
    }

    const handleSearchByEnter = (e) => {
        if (e.key === 'Enter') {
            setPage(1)
            setSearchText(tempSearchText)
        }
    }

    // multi select filter
    // const cekListFilter = (idx) => {
    //     let style = []
    //     if (listStyleFilter.length > 0) {
    //         let valCheck = listStyleFilter.includes(idx)
    //         if (!valCheck) {
    //             style = [...listStyleFilter, idx]
    //             setListStyleFilter(style)
    //         } else {
    //             let notIn = []
    //             listStyleFilter.map((item) => {
    //                 console.log('-> ', item, '=', idx)
    //                 if (item != idx) {
    //                     notIn.push(item)
    //                 }
    //             })
    //             setListStyleFilter(notIn)
    //         }
    //     } else {
    //         style = [...listStyleFilter, idx]
    //         setListStyleFilter(style)
    //     }
    // }

    const cekListFilter = (idx, Dataitem) => {
        let style = []
        if (listStyleFilter.length > 0) {
            let valCheck = listStyleFilter.includes(idx)
            if (!valCheck) {
                style = [idx]
                setListStyleFilter(style)
                handleFilter(Dataitem)
            } else {
                let notIn = []
                listStyleFilter.map((item) => {
                    if (item != idx) {
                        notIn.push(item)
                    }
                })
                setListStyleFilter(notIn)
                handleFilter("")
            }
        } else {
            style = [idx]
            setListStyleFilter(style)
            handleFilter(Dataitem)
        }
    }

    const handleFilter = (data) => {
        setTempFilterId(data?.id)
    }

    const handleCancelFilter = () => {
        setListStyleFilter(backupListStyleFilter)
        setShow(false)
    }
    
    const handleSaveFilter = () => {
        setPage(1)
        setBackupListStyleFilter(listStyleFilter)
        setFilterId(tempFilterId)
        setShow(false)
    };

    return(
        <div className="register">
            <Sidebar showSidebar={sidebar} close={handleRef}/>
            <div className="header-register">
                <img className="logo" src={LogoHeader}></img>
                        <p><b>Galeri CPI</b></p>
                        <img onClick={()=>handleSidebar(true)} className="nav-button" src={NavbarIcon}></img>
            </div>
            <div className="form-gallery">
                <p className="title-label-gallery">Lokasi Klaim Toko Hadiah</p>

                <div className="box-search-filter">
                    <div className="search-region" style={{width:'83%'}}>
                        <div className="col-search btn-icon-search">
                            <i class="fa fa-search" onClick={handleSearch}></i>
                            <input type="text" placeholder="Cari kota atau toko" id="search-berita" onChange={changeSearch} onKeyDown={handleSearchByEnter}></input>
                        </div>
                    </div>
                    <div className="search-region filter-region">
                        <div className="col-search" onClick={handleShow}>
                            <span className="iconify-inline" data-icon="mi:filter" data-width="30" data-height="30"></span>
                        </div>
                    </div>
                </div>

                <ListStore
                    data={store}
                    pagination={{
                        page,
                        totalData,
                        maxPage,
                        toggle: setPage
                    }}
                />

            </div>
            <Faq></Faq>

            
            <Modal show={show} onHide={handleCancelFilter}>
                <div style={{backgroundColor:'white', color:'black', borderRadius:'8px'}}>
                    <Modal.Header closeButton style={{color:'black'}}>
                        <Modal.Title style={{fontWeight:'bold', fontSize:'25px'}}>Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <span style={{fontSize:'16px'}}>Pilih Jenis Toko</span>
                            <div className="list-popup-filter">
                                <ul className="press-store">
                                    {listFilter.map((item, index) => {
                                        let check = listStyleFilter.includes(index)
                                        if (check) {
                                            return (
                                                // <li className="list-item-store-pick" onMouseDown={() => cekListFilter(index, item)} onClick={()=>handleFilter(item)}>
                                                <li className="list-item-store-pick" onMouseDown={() => cekListFilter(index, item)}>
                                                    <img src={item.image_url} alt=""></img>
                                                    <span>{item.name}</span>
                                                </li>
                                            )
                                        } else {
                                            return (
                                                <li className="list-item-store" onMouseDown={() => cekListFilter(index, item)}>
                                                    <img src={item.image_url} alt=""></img>
                                                    <span>{item.name}</span>
                                                </li>
                                            )
                                        }
                                    })}
                                </ul>
                            </div>
                        </div>
                        
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal-filter-footer">
                            <Button className="btn-batal" onClick={handleCancelFilter}>
                                Batal
                            </Button>
                            <Button className="btn-terapkan" onClick={handleSaveFilter}>
                                Terapkan
                            </Button>
                        </div>
                    </Modal.Footer>
                </div>
            </Modal>
        </div>
    );
}

export default Index