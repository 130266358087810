import React, { useState,useEffect,useRef } from "react";
import "./table.scss"
import Pagination from "./pagination/index";
import { ReactPhotoCollage } from "react-photo-collage";

import EmptyImage from "../../assets/img/landing-page/gambar_tidak_ditemukan.png"

const Index = ({ 
  columns, 
  data, 
  dark, 
  hover, 
  small, 
  pagination 
}) => {

  const setting = {
    width: '100%',
    height: ['70px', '70px', '70px', '70px', '70px'],
    layout: [4, 4, 4, 4, 4],
    photos: data,
    showNumOfRemainingPhotos: true
};

  const Refresh = ({data}) => {
    return (
      <ReactPhotoCollage {...setting} />
    );
  }

  return (
    <div>
    <div className="table-responsive">
      <div className="twibbon-peserta">
        {data.length > 0 ? 
          <Refresh data={data}/>
        :
          <div className="empty-twibbon">
            <img className="img-preview-empty" src={EmptyImage} alt="dummy"  />
            <label>Belum Ada Peserta Undian Promo CPI</label>
            <p>Maaf, daftar foto peserta  belum tersedia. Silahkan coba kata kunci lainnya.</p>

          </div>
        }
      </div>
    </div>
    <div style={{ width: "100%" }}>
      {data.length > 0 &&
        pagination && (
          <Pagination
            page={pagination?.page}
            dataTotal={data?.length}
            recordTotal={pagination?.totalData}
            maxPage={pagination?.maxPage}
            toggle={pagination?.toggle}
          />
        )
      }
    </div>
  </div>
  );
};

export default Index;
