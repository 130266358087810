// import { BehaviorSubject } from 'rxjs';
import { history } from "../../../utils/index";
import axios from "axios";
import { actionPending, actionSuccess, actionError } from "../action_types/index";

import { toastSuccess, toastError } from "../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_PROMO_PENDING = "GET_PROMO_PENDING";
export const GET_PROMO_SUCCESS = "GET_PROMO_SUCCESS";
export const GET_PROMO_ERROR = "GET_PROMO_ERROR";

// URL: URL_{URL}
const PROMO_URL = `${process.env.REACT_APP_API_URL}promo/public`;


// const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user_clinic')));

const promo = (param, resolve, id) => (dispatch) => {
  dispatch(actionPending(GET_PROMO_PENDING));
  axios
    .post(PROMO_URL,param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_PROMO_SUCCESS, res));
    //   toastSuccess("Register Berhasil")
      if (resolve){
        console.log("res action",res)
        let data = res.data.map((item, i) => ({
            ...item,
        }));
        resolve({
            data: res.data
        })
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_PROMO_ERROR));
      // toastError("Mengambil Data Galeri Gagal");
    });
};


const Promo = {
 promo,
};
export default Promo;
