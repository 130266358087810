import React, { useState,useEffect } from "react";
import Register from "../../views/register/index"
import Logo from "../../assets/img/landing-page/logo singa ke bawah.png"
import { DisappearedLoading } from 'react-loadingg';
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel'
import GambarSuccess from '../../assets/img/response/success.png';
import GambarWinning from '../../assets/img/response/winning.png';
import Hadiah from '../../assets/img/landing-page/hadiah.png'
import Kenal from '../../assets/img/landing-page/kenal.png'


const Index = ({locations, promos, availPromos})=> {
    const [ loading, setIsloading] = useState(true);
    const [ loading2, setIsloading2] = useState(true);
    const location = locations? locations : false;
    const promo = promos? true : false;
    const availPromo = availPromos? availPromos : false

    useEffect(() => {
        let data = promos? promos: null
        handleCekAvailPromo(data)
        // setTimeout( () => {
        //    setIsloading2(false);
        // },5000);
    }, [promos])
    const handleCekAvailPromo = (data) => {
      let dat = data
      if (dat === null || dat === "promo tersedia"){
        setIsloading(true)
      }else{
        setIsloading(false)
      }
    
    }
    const handleRegisterNow = () => {
      setIsloading(false);
      setIsloading2(false);
    }
    return (
            <div className={loading === true || loading2 === true|| location === false || promo == false ? "splash": "splash panjang" }>
                <Carousel controls={false}>
                  <Carousel.Item interval={2000}>
                    {/* <div style={{color:"#ffffff",float:"right",marginTop:"30px",marginRight:"30px"}}>
                      <p onClick={(e)=>handleRegisterNow()} style={{cursor:"pointer"}}>Lewati</p>
                    </div> */}
                    <img className="landing-logo" src={Logo}></img>
                  </Carousel.Item>
                  <Carousel.Item interval={2000}>
                    <div className="content"style={{color:"#ffffff"}}>
                      <div className="logo-response">
                          <img className="gambar-failed"src={Kenal}/>
                      </div>
                      <div className="caption-response-splash">
                            <h5><b>Yuk Lebih Kenal Dengan CPI</b></h5>
                            <p>PT Charoen Pokphand Indonesia merupakan perusahaan yang bergerak dalam bidang pakan ternak, menjual anak ayam usia satu hari serta membudidayakan ayam pedaging bersama dengan pengolahannya, dan makanan olahan yang memiliki kualitas tinggi dan berinovasi.</p>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item interval={2000}>
                      <div className="content" style={{color:"#ffffff"}}>
                          <div className="logo-response">
                              <img className="gambar-failed"src={Hadiah}/>
                          </div>
                          <div className="caption-response-splash">
                                <h5><b>Dapatkan Hadiah Menarik Bernilai Jutaan Rupiah Setiap Bulan</b></h5>
                                <p>Daftar sekarang dan menangkan kesempatan untuk mendapatkan voucher belanja dari Undian Promo CPI yang akan diundi setiap akhir bulan selama periode 2024.</p>
                          </div>
                      </div>
                  </Carousel.Item>
                </Carousel>
                <div className="daftar-sekarang">
                <button onClick={(e)=>handleRegisterNow()} style={{cursor:"pointer"}}>Daftar Sekarang
                </button>
            </div>
            </div>
    )
}
export default Index;