// import { BehaviorSubject } from 'rxjs';
import { history } from "../../../utils/index";
import axios from "axios";
import { actionPending, actionSuccess, actionError } from "../action_types/index";

import { toastSuccess, toastError } from "../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_CHECK_VOUCHER_PENDING = "GET_CHECK_VOUCHER_PENDING";
export const GET_CHECK_VOUCHER_SUCCESS = "GET_CHECK_VOUCHER_SUCCESS";
export const GET_CHECK_VOUCHER_ERROR = "GET_CHECK_VOUCHER_ERROR";
export const POST_REDEEM_VOUCHER_PENDING = "POST_REDEEM_VOUCHER_PENDING";
export const POST_REDEEM_VOUCHER_SUCCESS = "POST_REDEEM_VOUCHER_SUCCESS";
export const POST_REDEEM_VOUCHER_ERROR = "POST_REDEEM_VOUCHER_ERROR";
export const GET_AVAILABLE_VOUCHER_PENDING = "GET_AVAILABLE_VOUCHER_PENDING";
export const GET_AVAILABLE_VOUCHER_SUCCESS = "GET_AVAILABLE_VOUCHER_SUCCESS";
export const GET_AVAILABLE_VOUCHER_ERROR = "GET_AVAILABLE_VOUCHER_ERROR";
// URL: URL_{URL}POST
const CHECK_VOUCHER_URL = `${process.env.REACT_APP_API_URL}voucher/detail`;
const REDEEM_VOUCHER_URL = `${process.env.REACT_APP_API_URL}voucher/claim`;
const CHECK_AVAILABLE_VOUCHER_URL = `${process.env.REACT_APP_API_URL}voucher/validation`;

// const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user_clinic')));


const check_voucher = (resolve, param) => (dispatch) => {
  dispatch(actionPending(GET_CHECK_VOUCHER_PENDING));
  axios
    .post(CHECK_VOUCHER_URL, param)
    .then((res)=>{
      if (res.error){
        throw res.error;
     } 
    dispatch(actionSuccess(GET_CHECK_VOUCHER_SUCCESS, res));
    if (resolve){
      let data = res.data
      resolve({
        data: data.data[0]
      });
    }
    })
    .catch((err)=> {
    //   if (resolve){
    //     let data = err
    //     resolve({
    //       data: data.response.data
    //     })
    //   }
      history.push('/');
      //window.location.replace(`https://www.cpindonesia.com/redeem/voucher/${param.id}`)
      dispatch(actionError(GET_CHECK_VOUCHER_ERROR));
    })
}

const redeem_voucher = (resolve, param) => (dispatch) => {
  dispatch(actionPending(POST_REDEEM_VOUCHER_PENDING));
  axios
    .post(REDEEM_VOUCHER_URL, param)
    .then((res)=>{
      if (res.error){
        throw res.error;
     } 
    dispatch(actionSuccess(POST_REDEEM_VOUCHER_SUCCESS, res));
    if (resolve){
      let data = res.data
      resolve({
        data: data
      });
    }
    })
    .catch((err)=> {
      if (resolve){
        let data = err
        resolve({
          data: data.response.data
        })
      }
      dispatch(actionError(POST_REDEEM_VOUCHER_ERROR, err));
    })
}

const check_available_voucher = (resolve, param) => (dispatch) => {
  dispatch(actionPending(GET_AVAILABLE_VOUCHER_PENDING));
  axios
    .post(CHECK_AVAILABLE_VOUCHER_URL, param)
    .then((res)=>{
      if (res.error){
        throw res.error;
     } 
    dispatch(actionSuccess(GET_AVAILABLE_VOUCHER_SUCCESS, res));
    if (resolve){
      let data = res.data
      resolve({
        data: data
      });
    }
    })
    .catch((err)=> {
      if (resolve){
        let data = err
        resolve({
          data: data.response.data
        })
      }
      dispatch(actionError(GET_AVAILABLE_VOUCHER_ERROR, err));
    })
}

const Voucher = {
  check_voucher,
  redeem_voucher,
  check_available_voucher
};
export default Voucher;
