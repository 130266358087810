import {
    GET_GALLERY_PENDING,
    GET_GALLERY_SUCCESS,
    GET_GALLERY_ERROR,
  } from "../../actions/gallery/index";
  
  const initialState = {
    pending: false,
    error : null,
    data: null,
  }
  const gallery = (state = initialState, action) => {
    switch (action.type) {
      case GET_GALLERY_PENDING:
        return {
          ...state,
          pending: true,
        };
      case GET_GALLERY_SUCCESS:
        return {
          ...state,
          pending: false,
          data: action.data
        };
      case GET_GALLERY_ERROR:
        return {
          ...state,
          pending: true,
          error: action.error
        };
      default:
        return state;
    }
  };
  
  export default gallery;
  