// import { BehaviorSubject } from 'rxjs';
import { history } from "../../../utils/index";
import axios from "axios";
import { actionPending, actionSuccess, actionError } from "../action_types/index";

import { toastSuccess, toastError } from "../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_GALLERY_PENDING = "GET_GALLERY_PENDING";
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_GALLERY_ERROR = "GET_GALLERY_ERROR";
export const GET_GALLERY_PHOTO_PENDING = "GET_GALLERY_PHOTO_PENDING";
export const GET_GALLERY_PHOTO_SUCCESS = "GET_GALLERY_PHOTO_SUCCESS";
export const GET_GALLERY_PHOTO_ERROR = "GET_GALLERY_PHOTO_ERROR";

// URL: URL_{URL}
const GALLERY_URL = `${process.env.REACT_APP_API_URL}gallery`;
const GALLERY_PHOTO_URL = `${process.env.REACT_APP_API_URL}gallery/twibbon`;


// const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user_clinic')));

const gallery = (param, type, resolve, id) => (dispatch) => {
  dispatch(actionPending(GET_GALLERY_PENDING));
  axios
    .post(type == "list" ? GALLERY_URL : GALLERY_URL +  `/detail`,param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_GALLERY_SUCCESS, res));
    //   toastSuccess("Register Berhasil")
    if (resolve){
      let data;
      if (type === "list"){
        let img_url = []
        let vid_url = [] 
          data = res.data.data.map((item, i) => ({
            ...item,
            img_url: JSON.parse(item.image_url)
          }));
         
      }else{
        data = res.data.data
        let img_url = JSON.parse(data.image_url)
        let vid_url = JSON.parse(data.video_url)
        data['img_url'] = img_url
        data['vid_url'] = vid_url
       
      }
      resolve({
        data: data
      })
      
    }
    })
    .catch((err) => {
      dispatch(actionError(GET_GALLERY_ERROR));
      // toastError("Mengambil Data Galeri Gagal");
    });
};

const galleryPhoto = (param, resolve, id) => (dispatch) => {
  dispatch(actionPending(GET_GALLERY_PENDING));
  axios
    .post(GALLERY_PHOTO_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_GALLERY_SUCCESS, res));
    if (resolve){
      let data;
      
      data = res.data
      resolve({
        data: data
      })
      
    }
    })
    .catch((err) => {
      resolve({
        data: {
          data: []
        },
        length: 0,
        page: 0,
        records_filtered: 0,
        records_total: 0
      })
      dispatch(actionError(GET_GALLERY_ERROR));
      // toastError("Mengambil Data Galeri Gagal");
    });
};


const Gallery = {
 gallery, galleryPhoto
};
export default Gallery;
