import React, { useState,useEffect,useRef } from "react";
import { toastError, toastSuccess } from '../../components/commons/toast/index';
import * as Yup from 'yup';
import GambarSuccess from '../../assets/img/response/gagal.png';
import { history } from "../../utils/index";


const Index = ({message}) => {
const handleGallery = () =>{
        history.push("/")
    }
    return(
        <div className="content">
           <div className="logo-response">
               <img className="gambar-failed"src={GambarSuccess}/>
           </div>
           <div className="caption-response">
                <h5><b>Redeem Gagal</b></h5>
                <p>{message}</p>
           </div>
           <button onClick={(e)=>handleGallery()} className="button-kirim">
                Oke
            </button>
        </div>
    );
}

export default Index