// import { BehaviorSubject } from 'rxjs';
import { history } from "../../../utils/index";
import axios from "axios";
import { actionPending, actionSuccess, actionError } from "../action_types/index";

import { toastSuccess, toastError } from "../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const POST_REGISTER_PENDING = "POST_REGISTER_PENDING";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_ERROR = "POST_REGISTER_ERROR";
export const GET_CHECK_PROMO_PENDING = "GET_CHECK_PROMO_PENDING";
export const GET_CHECK_PROMO_SUCCESS = "GET_CHECK_PROMO_SUCCESS";
export const GET_CHECK_PROMO_ERROR = "GET_CHECK_PROMO_ERROR";
export const GET_CITY_PENDING = "GET_CITY_PENDING";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_ERROR = "GET_CITY_ERROR";
export const GET_JOB_PENDING = 'GET_JOB_PENDING';
export const GET_JOB_SUCCESS = 'GET_JOB_SUCCESS';
export const GET_JOB_ERROR = 'GET_JOB_ERROR';
 
// URL: URL_{URL}
const REGISTER_URL = `${process.env.REACT_APP_API_URL}promotion/registration`;
const CHECK_PROMO_URL = `${process.env.REACT_APP_API_URL}checkpromo/validasi`;
const CITY_URL = `${process.env.REACT_APP_API_URL}city`;
const JOB_URL = `${process.env.REACT_APP_API_URL}job`;

// const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user_clinic')));

const register = (resolve, param) => (dispatch) => {
  dispatch(actionPending(POST_REGISTER_PENDING));
  axios
    .post(REGISTER_URL, param, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }})
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      if (resolve){
        resolve({
          status: res.status
        });
      }
      dispatch(actionSuccess(POST_REGISTER_SUCCESS, res));
      // toastSuccess("Register Berhasil")
    })
    .catch((err) => {
      if (resolve){
        resolve({
          data: err.response.data
        })
      }
      dispatch(actionError(POST_REGISTER_ERROR));
      // toastError("Register Gagal");
    });
};

const check_promo = (resolve, param) => (dispatch) => {
  dispatch(actionPending(GET_CHECK_PROMO_PENDING));
  axios
    .post(CHECK_PROMO_URL, param)
    .then((res)=>{
      if (res.error){
        throw res.error;
     } 
    dispatch(actionSuccess(GET_CHECK_PROMO_SUCCESS, res));
    if (resolve){
      let data = res.data
      resolve({
        data: data
      });
    }
    })
    .catch((err)=> {
      if (resolve){
        let data = err
        resolve({
          data: data.response.data
        })
      }
      dispatch(actionError(GET_CHECK_PROMO_ERROR));
    })
}

const get_city = (resolve, param) => (dispatch) => {
  dispatch(actionPending(GET_CITY_PENDING));
  axios
    .post(CITY_URL, param)
    .then((res)=>{
      if (res.error){
        throw res.error;
     } 
    dispatch(actionSuccess(GET_CITY_SUCCESS, res));
    if (resolve){
      let data = res.data
      resolve({
        data: data
      });
    }
    })
    .catch((err)=> {
      if (resolve){
        let data = err
        resolve({
          data: data.response.data
        })
      }
      dispatch(actionError(GET_CITY_ERROR));
    })
}

const get_job = (resolve, param) => (dispatch) => {
  dispatch(actionPending(GET_JOB_PENDING));
  axios
    .post(JOB_URL, param)
    .then((res)=>{
      if (res.error){
        throw res.error;
     } 
    dispatch(actionSuccess(GET_JOB_SUCCESS, res));
    if (resolve){
      let data = res.data
      resolve({
        data: data
      });
    }
    })
    .catch((err)=> {
      if (resolve){
        let data = err
        resolve({
          data: data.response.data
        })
      }
      dispatch(actionError(GET_JOB_ERROR));
    })
}

const Register = {
  register,
  check_promo,
  get_city,
  get_job
};
export default Register;
