import React from "react";
import ReactPaginate from 'react-paginate';

const Index = ({ toggle, dataTotal, recordTotal, maxPage, page }) => {

    const handlePageClick = (event) => {
        if (toggle) {
            toggle(event.selected + 1)
        }

    };

    return (
        <>
            {
                recordTotal !== 0 && recordTotal !== null && (
                    <>
                        <div style={{ textAlign: 'center', justifyItems: 'center', fontSize:'13px' }}>
                            <div className="mini-hide">
                                <p style={{ margin: 0 }}>{`Menampilkan ${dataTotal ?? 0} dari ${recordTotal ?? 0}`}</p>
                            </div>
                            <div className="mini-hide">
                                <ReactPaginate
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    previousLabel={(<span className="material-icons-round" style={{fontSize:'15px'}}>arrow_back</span>)}
                                    previousLinkClassName={"page-link"}
                                    nextLabel={(<span className="material-icons-round" style={{fontSize:'15px'}}>arrow_forward</span>)}
                                    nextLinkClassName={"page-link"}
                                    pageCount={maxPage}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    breakClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    onPageChange={(event) => handlePageClick(event)}
                                    forceSelected={page - 1}
                                    forcePage={page - 1}
                                    initialPage={page - 1}
                                />
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default Index
