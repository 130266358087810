import React, { useState,useEffect,useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import Input from "../../components/forms/Input/index";
// import Select from "../../components/forms/Select2/index";
import File from "../../components/forms/File/index"
import LogoHeader from "../../assets/img/register/header_logo_2.png"
import UploadIcon from "../../assets/img/register/image.png"
import { toastError, toastSuccess } from '../../components/commons/toast/index';
import Splash from '../../components/splash/index';
import * as Yup from 'yup';
import { history } from "../../utils";
import Register from "../../store/actions/register/index";
import {generateDownload} from '../../views/register/Crop';
import Cropper from '../register/CropGambar';
import Twibbon from '../register/Twibbon';
// import Whatsapp from '../../components/contact/Index';
import Faq from "../../components/faq/FloatingMenu";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Container, Row, Col } from 'reactstrap';
import GetCity from '../../store/actions/register/index';
import Select2 from "../../components/forms/Select2";
import Loader from "../../components/commons/Loader";
import html2canvas from "html2canvas";


const Index = ({action,data, pending, location, idpromo, dataTwibbon}) => {
    const formikRef = useRef();
    const fileRef = useRef();
    const dispatch = useDispatch();
    const formData = new FormData();
    const [dataForCrop, setDataForCrop] = useState();
    const [crop, setCrop] = useState();
    const [twibbon, setTwibbon] = useState(false);
    const [dataForTwibbon, setDataForTwibbon] = useState();
    const [gambarUrl, setGambarUrl] = useState();
    const [dataFromCrop, setDataFromCrop] = useState();
    const [dataRegister, setDataRegister] = useState([]);
    const [image, setImage] = useState({ preview: "", raw: "", filename: "" });
    const [fileImage, setFile] = useState({ error: ""});    
    const [checkedPrivacy, setCheckedPrivacy] = useState(false); 
    const [dataCity, setDataCity] = useState([]);
    const [dataTwibbonToImage, setDataTwibbonToImage] = useState();
    const [dataJob, setDataJob] = useState([])
    const [jobOther, setJobOther] = useState(false)

    useEffect(() => {
        cekData();
        getDataCity();
        getDataJob();
    }, [])

    const cekData = () => {
        setDataRegister(JSON.parse(localStorage.getItem('userRegis')))
    }

    const getDataCity = () => {
        new Promise((resolve) => {
            let param = {
                page: 1,
                length: 999,
            }
            dispatch(GetCity.get_city(resolve, param));
        })
            .then(res => {
                let dataCity = []
                res?.data?.data.map((item) => {
                    dataCity = [...dataCity, { value: item.id, label: item.city_name }]
                })
                setDataCity(dataCity)
            })
    }

    const getDataJob = () => {
        new Promise((resolve)=>{
            let param = {
                page: 1,
                length: 999,
            }
            dispatch(GetCity.get_job(resolve, param));
        })
        .then(res => {
            let dataJob = []
            res?.data?.data.map((item)=>{
                dataJob = [...dataJob, { value: item.name, label: item.name }]
            })
            dataJob.push({value: "other", label: "Lainnya"})
            setDataJob(dataJob)
        })
    }

    const initial =  dataFromCrop ? 
    {
        name: dataFromCrop.name,
        no_whatsapp: dataFromCrop.no_whatsapp,
        nik: dataFromCrop.nik,
        photo: dataFromCrop.gambar? dataFromCrop.gambar : null,
        longitude: location.longitude,
        latitude: location.latitude,
        idpromo : idpromo,
        city : dataFromCrop.city,
        job_id: dataFromCrop.job_id,
        job_custom: dataFromCrop.job_custom
    } 
    : 
    {
        name: "",
        no_whatsapp: "",
        nik: "",
        photo: "",
        longitude: location.longitude,
        latitude: location.latitude,
        idpromo : idpromo,
        city : "",
        job_id: "",
        job_custom: "",
    }

    const initialTouched = {
        name: true,
        no_whatsapp: true,
        nik: true,
        city: true,
        job_id: true,
        job_custom: true,
    }
   

    const handleChangeImage = (e, formik) => {
        if (e.target.files.length) {
            formik.setFieldValue('photo',e.target.files[0])
            if ((e.target.files[0].type).includes("video")) {
                setFile({error:"Format harus berupa gambar"})
                setImage({
                    preview: "",
                    raw: "",
                    filename: ""
                });
                // return false
            } else {
                // if ((e.target.files[0].size/1024/1024) > 2) {
                //     setFile({error:"Ukuran foto tidak boleh lebih dari 2MB"})
                //     setImage({
                //         preview: "",
                //         raw: "",
                //         filename: ""
                //     });
                //     // return false
                // } else {
                    // history.push({
                    //     pathname: '/crop-gambar',
                    //     state: {
                    //         idpromo: formik.values.idpromo,
                    //         latitude: formik.values.latitude,
                    //         longitude: formik.values.longitude,
                    //         name: formik.values.name,
                    //         no_whatsapp: formik.values.no_whatsapp,
                    //         gambar : e.target.files[0],
                    //     } 
                    // })
        
                    // setImage({
                    //     preview: URL.createObjectURL(e.target.files[0]),
                    //     raw: e.target.files[0],
                    //     filename: e.target.files[0].name
                    // });
                // }

                let data = {
                    idpromo: formik.values.idpromo,
                    latitude: formik.values.latitude,
                    longitude: formik.values.longitude,
                    name: formik.values.name,
                    no_whatsapp: formik.values.no_whatsapp,
                    nik: formik.values.nik,
                    gambar : e.target.files[0],
                    city : formik.values.city,
                    job_id: formik.values.job_id,
                    job_custom: formik.values.job_custom,
                  }
                    setDataForCrop(data)
                    setCrop(true);
                // history.push({
                //     pathname: '/crop-gambar',
                //     state: {
                //         idpromo: formik.values.idpromo,
                //         latitude: formik.values.latitude,
                //         longitude: formik.values.longitude,
                //         name: formik.values.name,
                //         no_whatsapp: formik.values.no_whatsapp,
                //         gambar : e.target.files[0],
                //     } 
                // })
    
                setImage({
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0],
                    filename: e.target.files[0].name
                });
            }
        }
      };

    const FILE_SIZE = 160 * 1024;
    const validation = Yup.object().shape({
        name :Yup.string().required("Nama tidak boleh kosong").nullable(),
        city :Yup.string().required("Kota tidak boleh kosong").nullable(),
        job_id :Yup.string().required("Pekerjaan tidak boleh kosong").nullable(),
        job_custom: Yup.string() .when("job_id", {
            is: (val) => val === "other",
            then: (schema) => schema.required("Pekerjaan tidak boleh kosong"),
          })
          .nullable(),
        nik :Yup.number()
            .required("NIK tidak boleh kosong")
            .nullable()
            .test('len', 'Angka harus 16 digit', val => {
                if (val) {
                    let angka = val.toString()
                    return angka.length == 16
                }
            }),
        no_whatsapp : Yup.number()
            .typeError("Hanya dapat diisi angka dan lebih dari 0")
            .required("Nomor WhatsApp tidak boleh kosong")
            .nullable()
            .test('len', 'Angka harus lebih dari 10 digit', val => {
                if (val) {
                    let angka = val.toString()
                    if (angka.charAt(0) != '0') {
                        return ("0" + angka).length >= 10
                    } else {
                        return angka.length >= 10
                    }
                }
            }),
        photo : Yup.mixed()
        .test('fileFormat', 'Empty File', (value) => {

            if (!value) {
                setFile({error:"Foto tidak boleh kosong"})
                return false
            } else {
                if (value.type == "video/mp4") {
                    setFile({error:"Format harus berupa gambar"})
                    setImage({
                        preview: "",
                        raw: "",
                        filename: ""
                    });
                    return false
                } else {
    
                    // if ((value.size/1024/1024) > 2) {
                    //     setFile({error:"Ukuran foto tidak boleh lebih dari 2MB"})
                    //     setImage({
                    //         preview: "",
                    //         raw: "",
                    //         filename: ""
                    //     });
                    //     return false
                    // } else {
                    // }
                    setFile({error:""})
                    return true
                }
            }


        }),
    })
    
    function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:mime});
    }

    function blobtoFile(dataBlob) {
        dataBlob.name = "image.jpeg"
        dataBlob.lastModified = new Date();
        const myFile = new File([dataBlob], "image.jpeg", {
            type: dataBlob.type,
        });
        return myFile
    }
    const handleUploadImage = (value) => {
        // setCrop(false);
        setDataFromCrop(value);
        setGambarUrl(value.gambar);
        // handleSetImage(value);
        
    }
    const handleBack = (value) => {
        setCrop(value)
    }
    useEffect(() => {
        setCrop(false);
        let newImage = image

        // newImage.preview = gambarUrl?.toDataURL("image/png")
        // newImage.preview= newImage.preview? handleToFile(newImage?.preview) : ""
        // newImage.preview = newImage.preview? URL.createObjectURL(newImage.preview) : ""

        newImage.preview = gambarUrl? gambarUrl : ""

        setImage(newImage);
        
        convertDataTwibbonToImage();
    }, [gambarUrl])

    const handleToFile = (url) => {
        let dataBlob = dataURLtoBlob(url)

        dataBlob.name = "image.jpeg"
        dataBlob.lastModified = new Date().getTime()
        dataBlob.lastModifiedDate = new Date();
        const myFile = new File([dataBlob], "image.jpeg", {
            type: dataBlob.type,
        });
        return (myFile.props[0])
    }

    const [values, setValues] = React.useState([
        "Semua",
        "Diklaim",
        "Belum diklaim"
      ]);
    const [selected, setSelected] = useState("Semua");
    const [filter, setFilter] = useState("");

    const handleChange = (event) => {
        setSelected(event.target.value);
        setFilter(event.target.value == "Semua" ? "" : event.target.value == "Diklaim" ? "diklaim" : "belum diklaim")
    }

    const handleToPrivacy = () => {
        history.push('/privacy-policy')
    }

    const [showLoading, setShowLoading] = useState(false);
    const handleChangePrivacy = () => {
        setCheckedPrivacy(!checkedPrivacy)
    }
    
    const showLoadingSection = () => {
        setShowLoading(!showLoading)
    }
    
    const convertDataTwibbonToImage = () => {
        const img = new Image();
        img.onload = function() {
            generateDownload(process.env.REACT_APP_API_URL + dataTwibbon.twibbon_url, {height: this.height, width: this.width, x: 0, y: 0}, true).then(val => {
                let Datatw = {
                    name : values.name,
                    gambar : image,
                    bg : val
                }
                console.log(Datatw)
                setDataTwibbonToImage(Datatw)
            })
        }
        img.src = process.env.REACT_APP_API_URL + dataTwibbon?.twibbon_url;
    }

    return(
        <div className="register">
            <div className="header-register">
                <img className="logo" src={LogoHeader}></img>
                <p>{twibbon ? "Twibbon Foto" : crop ? "Twibbon Foto" : "Form Pendaftaran" }</p>
            </div>

            {twibbon ? <Twibbon dataX={dataForTwibbon}></Twibbon> : 
                crop ? <Cropper dataq={dataForCrop} dataTB={dataTwibbon} action={handleUploadImage} back={handleBack}></Cropper> :

                <div className="form-register">
                {showLoading && <Loader />}
                <div>
                    <p>Silakan Lengkapi data diri Anda terlebih dahulu</p>
                    <p class="warn-text">*data wajib diisi</p>
                </div>
                    <Formik  
                    innerRef={formikRef}
                    enableReinitialize
                    initialValues={initial}
                    initialTouched={initialTouched}
                    validationSchema = {validation}
                    onSubmit={(values) => {
                        showLoadingSection()
                        
                        let dataAll = document.getElementById('combineAllPhotoHidden')

                        dataAll.style.width = '300px';
                        dataAll.style.height = '300px';
                        dataAll.style.visibility = 'visible';
                        dataAll.style.display = 'flex';

                        html2canvas(dataAll).then((canvas)=>{
                            let dataBlob = dataURLtoBlob((canvas).toDataURL("image/jpeg",0.7))
                            let dataFile = blobtoFile(dataBlob)

                            let nomor = values.no_whatsapp.replace(/ /g, "")
                            nomor = nomor.replace('+62', "0")
    
                            formData.append('full_name',values.name);
                            formData.append('phone_number',nomor);
                            formData.append('photo_url', dataFile.props[0]);
                            formData.append('latitude',initial.latitude);
                            formData.append('longitude',initial.longitude);
                            formData.append('city_id',values.city);
                            formData.append('nik',values.nik);
                            formData.append('job_id',values.job_id)
                            if(jobOther){
                                formData.append('job_id',values.job_custom)
                            }
                            // hapus local
                            localStorage.removeItem('userRegis');
                            localStorage.removeItem('gambarUrl');
                            
                            new Promise((resolve)=>{
                                dispatch(Register.register(resolve, formData));
                            })
                            .then(res => {
                                var status = res.status 
                                if (status == 200){
                                    const img = new Image();
                                    img.onload = function() {
                                        generateDownload(process.env.REACT_APP_API_URL + dataTwibbon.twibbon_url, {height: this.height, width: this.width, x: 0, y: 0}, true).then(val => {
                                            let Datatw = {
                                                name : values.name,
                                                gambar : image,
                                                bg : val
                                            }
                                            setDataForTwibbon(Datatw)
                                            setTwibbon(true)
                                        })
                                    }
                                    img.src = process.env.REACT_APP_API_URL + dataTwibbon.twibbon_url;
                                }else{
                                    var message = (res.data.message)
                                    history.push({
                                        pathname: '/Failed',
                                        state: {
                                            status : status,
                                            message : message
                                        } 
                                    })
                                }
                                showLoadingSection()
                            })
                        })

                        dataAll.style.visibility = 'hidden';
                        dataAll.style.display = 'none';
                        
                    }}
                    >
                        {(props)=> (
                            <Form>
                                <Input
                                        name="name"
                                        title="Nama Lengkap"
                                        placeholder="Masukkan Nama Lengkap"
                                        type="text"
                                        touched={props.touched}
                                        errors={props?.errors}
                                        {...props.getFieldProps("name")}
                                    />
                                <Input
                                        name="nik"
                                        title="NIK"
                                        placeholder="Masukkan NIK"
                                        type="number"
                                        touched={props.touched}
                                        errors={props?.errors}
                                        {...props.getFieldProps("nik")}
                                />
                                <Input
                                        name="no_whatsapp"
                                        title="Nomor Whatsapp"
                                        placeholder="Masukkan Nomor Whatsapp"
                                        type="text"
                                        touched={props.touched}
                                        errors={props?.errors}
                                        {...props.getFieldProps("no_whatsapp")}

                                    />
                                <Select2
                                    title="Kota Saat Ini"
                                    name="city"
                                    options={dataCity}
                                    errors={props?.errors}
                                    touched={props?.touched}
                                    value={props?.values?.city}
                                    onChange={(name, value) => {
                                       
                                        props?.setFieldValue("city", value);
                                    }}
                                    onBlur={props?.setFieldTouched}
                                    
                                />

                                <Select2
                                    title="Pekerjaan"
                                    name="job_id"
                                    options={dataJob}
                                    errors={props?.errors}
                                    touched={props?.touched}
                                    value={props?.values?.job_id}
                                    onChange={(name, value) => {
                                        props?.setFieldValue("job_id", value);
                                        if (value === "other"){
                                            setJobOther(true)
                                            return
                                        }
                                        setJobOther(false)
                                    }}
                                    onBlur={props?.setFieldTouched}
                                    
                                />
                                {jobOther && 
                                <Input
                                        style={{marginTop: "10px",marginBottom: '10px'}}
                                        name="job_custom"
                                        placeholder="Masukkan Pekerjaan"
                                        type="text"
                                        value={props?.values?.job_custom}
                                        touched={props.touched}
                                        errors={props?.errors}
                                        {...props.getFieldProps("job_custom")}

                                    />
                                }
                                

                                <div className="form-group">
                                    <label className="form-label">Upload Foto Diri</label>
                                    <div className="box-upload" >
                                    <label htmlFor="upload-button">
                                        {image.preview ? (
                                        <div id="combineAllPhoto">
                                            <div className="bg-tb-form">
                                                <img className="img-preview" style={{position:"absolute"}} src={(dataTwibbonToImage?.bg)}/>
                                                <div>
                                                    <img className="img-preview" src={image.preview} alt="dummy"  />
                                                </div>
                                            </div>
                                        </div>
                                        ) : (
                                        <>
                                            <img className="img-preview" src={UploadIcon} />
                                        </>
                                        )}
                                        <div className="filename">
                                            <p><b>Foto Diri</b></p>
                                            <p style={{wordBreak:"break-all"}}>{image.filename ? image.filename : "jpg, png, Max 2MB"}</p>
                                        </div>
                                    </label>
                                    </div>
                                    {fileImage.error ? (
                                        <p class="warn-text">{fileImage.error}</p>
                                    ) : (
                                        <p></p>
                                    )}
                                    <input
                                        type="file"
                                        ref={fileRef}
                                        id="upload-button"
                                        style={{ display: "none" }}
                                        onChange={(e)=> handleChangeImage(e, props)}
                                        accept="image/*"
                                        errors={props?.errors}
                                        capture="camera"
                                    />
                                </div>
                                <div className="form-group">
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="privacy"
                                                onChange={handleChangePrivacy}
                                            />
                                            <label className="form-check-label">Saya Menyetujui <a className="form-privacy" onClick={() => handleToPrivacy()}>Ketentuan layanan, Kebijakan Privasi, dan Kebijakan Konten CPI Undian</a></label>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" disabled={pending?"disabled" : checkedPrivacy ? "" : "disabled"} className="button-kirim"
                               >
                                    Kirim
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            }  
            <Faq></Faq>
            {/* <Whatsapp></Whatsapp>           */}

            {/* hidden combine image*/}
            {image.preview ? (
            <div id="combineAllPhotoHidden" style={{visibility:"hidden", display:'none'}}>
                <div className="bg-tb-form-hidden">
                    <img className="img-preview" style={{position:"absolute"}} src={(dataTwibbonToImage?.bg)}/>
                    <div>
                        <img className="img-preview" src={image.preview} alt="dummy"  />
                    </div>
                </div>
            </div>
            ) : ('')}
        </div>
    );
}
const mapStateToProps = ({ register: { pending, data } }) => {
    return { pending, data };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Index)