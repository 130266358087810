import React, { useState,useEffect,useRef } from "react";
import { connect, useDispatch } from "react-redux";
import LogoHeader from "../../assets/img/register/header_logo_2.png"
import UploadIcon from "../../assets/img/register/image.png"
import { history } from "../../utils";
import 'font-awesome/css/font-awesome.min.css';
import Faq from '../../store/actions/faq/index';
import { Container, Row, Col } from 'reactstrap';
// import Whatsapp from '../../components/contact/Index';


const Index = ({action}) => {
    const [data, setData] = useState();
    const dispatch = useDispatch();
    const handleClickToBack = ()=>{
        history.goBack();
    }
    
    return(
        <div className="register">
            <div className="header-register">
                <img className="logo" src={LogoHeader}></img>
            </div>
            <div className="form-gallery">
                <Row>
                    <Col>
                        <button className="btn-detail-gallery" onClick={()=>handleClickToBack()}>
                                <i className="fa fa-arrow-left"></i>
                        </button>
                    </Col>
                    <Col xs={10}>
                        <p className="title-privacy"><b>Privacy & Policy</b></p>
                    </Col>
                </Row>

                <div className="box-rounded">
                    <table id="table-privacy">
                        <tr><th>1.</th><td>Keputusan pemenang Undian Promo Charoen Pokphand Indonesia ini bersifat mutlak dan tidak dapat diganggu gugat.</td></tr>
                        <tr><th>2.</th><td>Semua konten yang ada di dalam web ini menjadi hak milik PT Charoen Pokphand Indonesia.</td></tr>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Index