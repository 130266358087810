import React, { useState,useEffect,useRef, forwardRef, createRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import Input from "../../components/forms/Input/index";
import File from "../../components/forms/File/index"
import LogoHeader from "../../assets/img/register/header_logo_2.png"
import UploadIcon from "../../assets/img/register/image.png"
import EyeIcon from "../../assets/img/register/visibility.png"
import { toastError, toastSuccess } from '../../components/commons/toast/index';
import Splash from '../../components/splash/index';
import * as Yup from 'yup';
import { history } from "../../utils";
import Register from "../../store/actions/register/index";
import 'font-awesome/css/font-awesome.min.css';
import Gallery from '../../store/actions/gallery/index';
// import Whatsapp from '../../components/contact/Index';
import Faq from "../../components/faq/FloatingMenu";
import Carousel from 'react-bootstrap/Carousel'
import Sidebar from "../../components/layout/sidebar/index";
import NavbarIcon from "../../assets/img/register/navbar-icon.png"

const Index = ({action,data}) => {
    const [galeri, setGaleri] = useState([]);
    const [healdine, setHeadline] = useState([]);
    const [sidebar, setSidebar] = useState(false);
    // const ref = createRef();
    const handleClickToDetail = (id) => {
        history.push('./detail-gallery/'+id)
    }
    const handleClickToTwibbonPeserta = (id) => {
        history.push('./twibbon-peserta/'+id)
    }
    const dispatch = useDispatch();

    useEffect(() => {
        handleRefreshHeadline();
        handleRefresh();
    }, [])
    const handleRefresh = () =>{
        
        new Promise((resolve)=>{
            let param = {
                page: 1,
                length: 999,
            }
            dispatch(Gallery.gallery(param,"list",resolve))
        })
        .then(res => {
            setGaleri(res?.data)
        })
    }

    const handleRefreshHeadline = () => {
        
        new Promise((resolve)=>{
            let param = {
                page: 1,
                length: 999,
                headline: true,
            }
            dispatch(Gallery.gallery(param,"list",resolve))
        })
        .then(res => {
            setHeadline(res?.data)
        })
    }
    const handleSidebar = (value) => {
        if (value === false){
            console.log(value)
        }
       setSidebar(!sidebar)
    }
    const convertDate = (date) => {
        if (date != null) {
            let year = date.substring(0, 4)
            let month = date.substring(5, 7)
            let day = date.substring(8, 10)
            
            let listMonth = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
            month = listMonth[parseInt(month)-1]
    
            return `${day} ${month} ${year}`
        }
    }
    const handleRef = () => {
        setSidebar(false)
    }
    return(
        <div className="register">
            <Sidebar showSidebar={sidebar} close={handleRef}/>
            <div className="header-register">
                <img className="logo" src={LogoHeader}></img>
                        <p><b>Galeri CPI</b></p>
                        <img onClick={()=>handleSidebar(true)} className="nav-button" src={NavbarIcon}></img>
            </div>
            <div className="form-gallery">
                <p className="title-label-gallery">Berita Terbaru</p>
                <div className="gallery-image-home-slider">
                    <Carousel controls={false}>
                        { healdine.length > 0 ?  (healdine.map((gal,index) => {
                            if (index < 2) {
                                return (
                                    <Carousel.Item interval={3000}>
                                        <div className="card-gallery">
                                            <div className="gallery-image">
                                                {gal.image_url ? (
                                                    <img className="img-preview" src={process.env.REACT_APP_API_URL + gal.img_url[0]} alt="dummy"  />
                                                ) : (
                                                    <img className="img-preview" src={UploadIcon} alt="dummy"  />
                                                )}
                                            </div>
                                            <p className="title-gallery"><b>{gal.name}</b></p>
                                            <div className="row" style={{marginTop:"-10px"}}>
                                                <div className="col col-gallery">
                                                    <p className="desc-publish">Dipublish pada {convertDate(gal.published_at)}</p>
                                                </div>
                                                <div className="col-2 col-gallery">
                                                    <button className="btn-click-gallery" onClick={(e)=>handleClickToDetail(gal.id)}>
                                                        <i className="fa fa-arrow-right"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="section-eye">
                                                <span>
                                                    <img src={EyeIcon} className="icon-eye"></img>
                                                    <span className="count-eye">{gal.views}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                )
                            }
                            return (
                                <Carousel.Item interval={3000}>
                                    <div className="card-gallery">
                                        <div className="gallery-image">
                                            <img className="img-preview" style={{maxWidth:'180px'}} src={process.env.REACT_APP_API_URL + gal.img_url[0]} alt="dummy"  />
                                        </div>
                                        {/* <p className="title-gallery"><b>Pemenang Twibbon Promo Undian CPI Periode Bulan {gal.description}</b></p> */}
                                        <p className="title-gallery"><b>Peserta Undian dengan Twibbon Promo CPI 2022</b></p>
                                        <div className="row" style={{marginTop:"-10px"}}>
                                            <div className="col col-gallery">
                                                {/* <p className="desc-publish">Dipublish pada 11 Mei 2021</p> */}
                                            </div>
                                            <div className="col-2 col-gallery">
                                                <button className="btn-click-gallery" onClick={(e)=>handleClickToTwibbonPeserta(gal.id)}>
                                                    <i className="fa fa-arrow-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                        {/* <div className="section-eye">
                                            <span>
                                                <img src={EyeIcon} className="icon-eye"></img>
                                                <span className="count-eye">{gal.views}</span>
                                            </span>
                                        </div> */}
                                    </div>
                                </Carousel.Item>
                            )
                        }))  : "" }
                    </Carousel>
                </div>
                <p className="title-label-gallery">Berita Lainnya</p>
                { galeri.length > 0 ?  (galeri.map((gal,index) => {
                    return <div className="card-gallery">
                    <div className="gallery-image">
                        {gal.image_url ? (
                            <img className="img-preview" src={process.env.REACT_APP_API_URL + gal.img_url[0]} alt="dummy"  />
                        ) : (
                            <img className="img-preview" src={UploadIcon} alt="dummy"  />
                        )}
                    </div>
                    <p className="title-gallery"><b>{gal.name}</b></p>
                    <div className="row" style={{marginTop:"-10px"}}>
                        <div className="col col-gallery">
                            <p className="desc-publish">Dipublish pada {convertDate(gal.published_at)}</p>
                        </div>
                        <div className="col-2 col-gallery">
                            <button className="btn-click-gallery" onClick={(e)=>handleClickToDetail(gal.id)}>
                                <i className="fa fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                    <div className="section-eye">
                        <span>
                            <img src={EyeIcon} className="icon-eye"></img>
                            <span className="count-eye">{gal.views}</span>
                        </span>
                    </div>
                </div>
                }))  : "" }
            </div>
            <Faq></Faq>
            {/* <Whatsapp></Whatsapp>  */}
        </div>
    );
}

export default Index