import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import ButtonModal from '../modal/ButtonModal';
import HelpIcon from "../../assets/img/register/help.png"

function ConfirmationModal({
    show,
    toggle,
    title,
    message,
    isDelete,
    children,
    icon,
    close,
    variantIcon,
    isCancel,
    isConfirm,
    confirm
}) {

    return (
        <>
            <Modal id="modal-confirmation-redeem" isOpen={show} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 600 }}
                toggle={toggle}>
                {/* <ModalHeader className="text-extra-bold pt-5"> {title ?? "Konfirmasi"} <span className="close clickable click-in-right" onClick={toggle}>&times;</span></ModalHeader> */}
                <ModalBody>
                    <div style={{textAlign:"center"}}>
                       <img src={HelpIcon}></img> 
                       <h2 className="text-extra-bold">Konfirmasi</h2>
                    </div>
                    <div style={{ marginLeft: 20, display: 'flex', lineHeight: '2rem' }}>
                        {message && <p style={{ fontSize: '16px' }}>{message}</p>}
                        {children}
                    </div>
                    <div>
                        <button 
                          onClick={toggle}
                          className="button secondary"
                          type="button"
                          style={{marginRight:20}}
                          >
                            Batal
                        </button>
                        <button 
                          onClick={confirm}
                          className="button primary"
                          type="button"
                          >
                            Lanjut
                        </button>
                    </div>
                </ModalBody>
                {/* <ModalFooter>
                    <ButtonModal
                        toggle={toggle}
                        deleted={isDelete}
                        confirm={confirm}
                        cancelTitle={isCancel ?? 'Batal'}
                        confirmTitle={isConfirm ?? 'Simpan'}
                    />
                </ModalFooter> */}
            </Modal>
        </>
    )
}

export default ConfirmationModal
