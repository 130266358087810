import { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { withRouter, NavLink, useLocation } from "react-router-dom";


import Promo from '../../../store/actions/promo/index.js'
import Logo from "../../../assets/img/landing-page/logo_biru.png"

const SidebarItem = ()=>{
    const dispatch = useDispatch();
    const [data, setData] = useState();
    const [idSelected, setIdSelected] = useState();
    const location = useLocation();
    const [datax, setDatax]= useState([]);
    useEffect(()=>{
        handleRefresh();
    }, [])
      const handleRefresh = () => {
          new Promise((resolve)=>{
            let param = {
                page: 1,
                length: 999,
            }
            dispatch(Promo.promo(param,resolve))
          })
          .then(res => {
            setData(res?.data)
            let selectedId;
            res?.data.map((item,index)=>{
              if(item.status === 1){
                setIdSelected(item.id)
                selectedId = item.id
              }
            })
           let dataq =  [
              {
                title: "Home",
                slug: "home",
                url: "/",
                icon: "ci:home-alt-outline",
              },
              {
                title: "Tentang Kami",
                slug: "about_us",
                url: "https://cp.co.id/",
                icon: "bx:bx-id-card",
              },
              {
                title: "Kumpulan Twibbon",
                slug: "twibbon",
                url: "twibbon-peserta/"+ selectedId,
                icon: "ci:image-alt",
              },
              {
                title: "FAQ",
                slug: "faq",
                url: "/faq",
                icon: "ci:chat",
              },
              {
                title: "Lokasi Klaim Toko Berhadiah",
                slug: "location",
                url: "/lokasisemuatoko",
                icon: "bx:store-alt",
              },
            ];
            setDatax(dataq)
        })
        }
   return (
    <ul className="nav-ul" id="nav-ul-sidebar">
        <li style={{paddingLeft:30,paddingBottom:30}}><img src={Logo} ></img></li>
          {datax && datax.length > 0 ? 
            datax.map((item, key) => {
              return(
                <li class={location.pathname === item.url? "nav-items active": "nav-items"} key={key}>
                  <a href={item.url}>
                      <span className="iconify-inline" data-icon={item.icon} data-width="23" data-height="23">
                          
                      </span>
                      <span className="sidebar-title">{item.title}</span>
                  </a>
                </li>
              )
            }) 
            : 
            ""}
    </ul>
   )
           
      
 }

export default SidebarItem;