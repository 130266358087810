import { useState, useRef, useEffect, forwardRef } from "react";
import { withRouter, NavLink, useLocation } from "react-router-dom";
import  SidebarItem from "./sidebarItem";
import Profile from "./profile";
import Notification from "./notification";
import Logo from "../../../assets/img/landing-page/logo_biru.png"

const Index = (props) => {
  const show = props.showSidebar? props.showSidebar : false;
  const location = useLocation()
   const ref = useRef();
   const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      // setSidebar(false);
      props.close()
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
        document.removeEventListener("click", handleClickOutside, true);
    };
}, [])
  return (
    <nav ref={ref} className={show? "nav-container show": "nav-container"}>
        <SidebarItem></SidebarItem>
    </nav>
  );
};

export default withRouter(Index);
