import React, { useState,useEffect,useRef } from "react";
import { toastError, toastSuccess } from '../../components/commons/toast/index';
import * as Yup from 'yup';
import GambarSuccess from '../../assets/img/response/gagal.png';
import { history } from "../../utils/index";
import LogoHeader from "../../assets/img/landing-page/logo_biru_2.png"


const Index = ({message}) => {
const mes = message
const handleGallery = () =>{
        history.push("/")
    }
    return(
        <div className="content">
            <div className="header-logo">
                <img className="logo" src={LogoHeader}></img>
            </div>
           <div className="logo-response">
               <img className="gambar-failed"src={GambarSuccess}/>
           </div>
           <div className="caption-response">
                <h5><b>Periode promo undian tidak sesuai</b></h5>
                <p>{mes}</p>
           </div>
           <button onClick={(e)=>handleGallery()} className="button-kirim">
                Oke
            </button>
        </div>
    );
}

export default Index