
// import gambar from "../asset/gambar.jpg"
// import gambar2 from "../asset/es krim.jpg"
// import gambar3 from "../asset/pesawat.png"
import UploadIcon from "../../../assets/img/register/image.png"
// import stiker1 from "../../../assets/img/register/stiker/stiker1.png"
// import stiker2 from "../../../assets/img/register/stiker/stiker2.png"
// import stiker3 from "../../../assets/img/register/stiker/stiker3.png"
// import stiker4 from "../../../assets/img/register/stiker/stiker4.png"
// import stiker5 from "../../../assets/img/register/stiker/stiker5.png"
// import stiker6 from "../../../assets/img/register/stiker/stiker6.png"

import stiker1 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-01.png"
import stiker2 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-02.png"
import stiker3 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-03.png"
import stiker4 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-04.png"
import stiker5 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-05.png"
import stiker6 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-06.png"
import stiker7 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-07.png"
import stiker8 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-08.png"
import stiker9 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-09.png"
import stiker10 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-10.png"
import stiker11 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-11.png"
import stiker12 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-12.png"
import stiker13 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-13.png"
import stiker14 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-14.png"
import stiker15 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-15.png"
import stiker16 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-16.png"
import stiker17 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-17.png"
import stiker18 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-18.png"
import stiker19 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-19.png"
import stiker20 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-20.png"
import stiker21 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-21.png"
import stiker22 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-22.png"
import stiker23 from "../../../assets/img/register/stiker/REAL CPI ASSET STIKER-23.png"

export const stickersData = [
    {
      url: stiker1,
      width: 60,
    },
    {
      url: stiker2,
      width: 60,
    },
    {
      url: stiker3,
      width: 60,
    },
    {
      url: stiker4,
      width: 60,
    },
    {
      url: stiker5,
      width: 60,
    },
    {
      url: stiker6,
      width: 60,
    },
    {
      url: stiker7,
      width: 60,
    },
    
    {
      url: stiker8,
      width: 60,
    },
    {
      url: stiker9,
      width: 60,
    },
    {
      url: stiker10,
      width: 60,
    },
    {
      url: stiker11,
      width: 60,
    },
    {
      url: stiker12,
      width: 60,
    },
    {
      url: stiker13,
      width: 60,
    },
    {
      url: stiker14,
      width: 60,
    },
    {
      url: stiker15,
      width: 60,
    },
    {
      url: stiker16,
      width: 60,
    },
    {
      url: stiker17,
      width: 60,
    },
    {
      url: stiker18,
      width: 60,
    },
    {
      url: stiker19,
      width: 60,
    },
    {
      url: stiker20,
      width: 60,
    },
    {
      url: stiker21,
      width: 60,
    },
    {
      url: stiker22,
      width: 60,
    },
    {
      url: stiker23,
      width: 60,
    },
  ];
  