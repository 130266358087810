import React, { useState,useEffect,useRef } from "react";
import { connect, useDispatch } from "react-redux";
import LogoHeader from "../../assets/img/register/header_logo_2.png"
import UploadIcon from "../../assets/img/register/image.png"
import { toastError, toastSuccess } from '../../components/commons/toast/index';
import { history } from "../../utils";
import Gallery from '../../store/actions/gallery/index';
import Carousel from 'react-bootstrap/Carousel'
import YouTube from "react-youtube";
import EyeIcon from "../../assets/img/register/visibility2.png"
import Sidebar from "../../components/layout/sidebar/index";
import NavbarIcon from "../../assets/img/register/navbar-icon.png"

const Index = ({match, action,data}) => {
    const [galeri, setGaleri] = useState([]);
    const [sidebar, setSidebar] = useState(false);

    const handleClickToBack = () => {
        history.push('/')
    }
    const dispatch = useDispatch();
    useEffect(() => {
        let id = match?.params?.id
        handleRefresh(id);
    }, [])
    const handleRefresh = (id) =>{
        
        new Promise((resolve)=>{
            let param = {
                id: id
            }
            dispatch(Gallery.gallery(param, "detail",resolve, id))
        })
        .then(res => {
            console.log(res)
            setGaleri(res?.data)
        })
    }

    const convertDate = (date) => {
        if (date != null) {
            let year = date.substring(0, 4)
            let month = date.substring(5, 7)
            let day = date.substring(8, 10)
            
            let listMonth = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
            month = listMonth[parseInt(month)-1]
    
            return `${day} ${month} ${year}`
        }
    }

    const handleClickNext = () => {
        let nextGallery = galeri.next_gallery
        handleRefresh(nextGallery)
    }
    const urlToEmbed = (dataUrl) => {
        console.log(dataUrl)
            const item = dataUrl.replace(/\\u0026/g, "&");
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const match =  item.match(regExp);
            console.log(match);
            let data = (match && match[2].length === 11)
            ? match[2]
            : null;
        console.log(data)
         
       return data
    }
    
    const opts = {
        playerVars: {
          autoplay: 0
        }
    };

    const [statusPlay, setStatusPlay] = useState(true);
    const [statusTimer, setStatusTimer] = useState(2000);

    const checkElapsedTime = (e) => {
        if(statusPlay){
            setStatusTimer(1000000)
            setStatusPlay(false)
        } else {
            if (e.data == 1){
                setStatusTimer(1000000)
            }
            if (e.data == 2 || e.data == -1){
                setStatusTimer(2000)
            }
        }
    };
    const handleSidebar = () => {
       setSidebar(!sidebar)
    }
    const handleRef = () => {
        setSidebar(false)
    }
    return(
        <div className="section-detail-gallery">
              <Sidebar showSidebar={sidebar} close={handleRef}/>
            <div className="header-register">
                <img className="logo" src={LogoHeader}></img>
                <p><b>Galeri CPI</b></p>
                <img onClick={()=>handleSidebar()} className="nav-button" src={NavbarIcon}></img>
            </div>
            <div className="form-detail-gallery">
                {galeri ?
                <div className="card-detail-gallery">
                    <button className="btn-detail-gallery" onClick={handleClickToBack}>
                        <i className="fa fa-arrow-left"></i>
                    </button>
                    <p className="title-gallery" id="titleGallery"><b>{galeri.name}</b></p>
                    <div class="row justify-content-between">
                        <div class="col-8">
                            <p className="desc-publish">Dipublish pada {convertDate(galeri.published_at)}</p>
                        </div>
                        <div class="col-4">
                            <div className="section-eye" style={{float:"right"}}>
                                <span>
                                    <img src={EyeIcon} className="icon-eye"></img>
                                    <span className="count-eye">{galeri.views}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-image">
                         <Carousel controls={false}>
                             {galeri.img_url ?
                                galeri.img_url?.map((item,index)=>{
                                    return  (
                                        <Carousel.Item interval={2000}>
                                         <img className="img-preview" src={process.env.REACT_APP_API_URL + item} alt="dummy"  />
                                       </Carousel.Item>
                                       )
                                })
                                :   (<img className="img-preview" src={UploadIcon} alt="dummy"  />)
                             }
                             {galeri.vid_url ? 
                                galeri.vid_url?.map((item,index)=>{
                                    return (
                                        <Carousel.Item interval={statusTimer}>
                                            {/* <iframe width="420" height="315" onClick={handlePlayVideo()} className="img-preview" controls src={"https://www.youtube.com/embed/"+ urlToEmbed(item)}></iframe> */}
                                            {/* <iframe src={process.env.REACT_APP_API_URL + galeri.video_url}></iframe> */}

                                            <YouTube
                                                videoId={item.split("v=")[1].split("&")[0]}
                                                containerClassName="embed embed-youtube"
                                                onStateChange={(e) => checkElapsedTime(e)}
                                                opts={opts}
                                                className="img-preview"
                                            />
                                            {/* <iframe width="420" height="315" onClick={handlePlayVideo()} className="img-preview" controls src={"https://www.youtube.com/embed/"+ urlToEmbed(item)}></iframe> */}
                                       </Carousel.Item>)
                                })
                            :""
                            }
                        </Carousel>
                    </div>

                    <p className="detail-gallery">{galeri.content}</p>
                    
                </div> : "" }
                
                {galeri.next_gallery ? (
                    <a href="#titleGallery" className="fixed-button-next" onClick={handleClickNext}>
                        Selanjutnya &nbsp; &nbsp;<i className="fa fa-arrow-right"></i>
                    </a>
                ) : ""}
            </div>
        </div>
    );
}

export default Index