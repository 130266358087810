import React, { useState,useEffect,useRef } from "react";
import "./table.scss"
import Pagination from "./pagination/index";
import { ReactPhotoCollage } from "react-photo-collage";

import EmptyImage from "../../assets/img/landing-page/gambar_tidak_ditemukan.png"

const Index = ({ 
  columns, 
  data, 
  dark, 
  hover, 
  small, 
  pagination 
}) => {

  const setting = {
    width: '100%',
    height: ['70px', '70px', '70px', '70px', '70px'],
    layout: [4, 4, 4, 4, 4],
    photos: data,
    showNumOfRemainingPhotos: true
};

  return (
    <div>
    <div className="table-responsive">
      <div className="twibbon-peserta">
        {data.length > 0 ? 
          // <LoadView data={data}/>
          data.map((item, index) => {
            return(
              <div className="list-store">
                <div className="box-list-store border-hr-list-store">
                    <div className="store-list-logo">
                        <img src={process.env.REACT_APP_API_URL + item.outlet_logo} alt=""></img>
                    </div>
                    <div className="title-list-logo">
                        <span>{item.name}</span>
                    </div>
                </div>
                <div className="box-list-store">
                    <div className="store-list-logo">
                        <span className="iconify-inline" data-icon="carbon:location-filled" data-width="30" data-height="30"></span>
                    </div>
                    <div className="location-list-store">
                        <span>{item.address}</span>
                    </div>
                </div>
                <div className="area-list-store">
                    <span>AREA: {item.area}</span>
                </div>
              </div>
            )
          })
        :
          <div className="empty-twibbon" style={{display:'grid'}}>
            <img className="img-preview-empty" src={EmptyImage} alt="dummy"  />
            <label>Belum Ada Lokasi Toko</label>
          </div>
        }
      </div>
    </div>
    <div style={{ width: "100%", marginBottom:"80px"}}>
      {data.length > 0 &&
        pagination && (
          <Pagination
            page={pagination?.page}
            dataTotal={data?.length}
            recordTotal={pagination?.totalData}
            maxPage={pagination?.maxPage}
            toggle={pagination?.toggle}
          />
        )
      }
    </div>
  </div>
  );
};

export default Index;
