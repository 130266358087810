const createImage = (url) =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener("load", () => resolve(image));
		image.addEventListener("error", (error) => reject(error));
		image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
		image.src = url;
	});

function getRadianAngle(degreeValue) {
	return (degreeValue * Math.PI) / 180;
}

export default async function getCroppedImg(imageSrc, pixelCrop, cekTw, rotation = 0) {
	const image = await createImage(imageSrc);
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");

	let maxSize
	if (cekTw) {
		maxSize = Math.max(image.width, image.height)
	} else {
		if ( navigator.platform != "iPad" && navigator.platform != "iPhone" && navigator.platform != "iPod" ) {
			maxSize = Math.max(image.width, image.height)
		} else {
			maxSize = Math.max(image.width, image.height)/2
		}
	}
	 
	const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

	// set each dimensions to double largest dimension to allow for a safe area for the
	// image to rotate in without being clipped by canvas context
	canvas.width = safeArea;
	canvas.height = safeArea;

	// translate canvas context to a central location on image to allow rotating around the center.
	ctx.translate(safeArea / 2, safeArea / 2);
	ctx.rotate(getRadianAngle(rotation));
	ctx.translate(-safeArea / 2, -safeArea / 2);

	// draw rotated image and store data.
	ctx.drawImage(
		image,
		safeArea / 2 - image.width * 0.5,
		safeArea / 2 - image.height * 0.5
	);

	const data = ctx.getImageData(0, 0, safeArea, safeArea);

	// set canvas width to final desired crop size - this will clear existing context
	if(cekTw) {
		canvas.width = pixelCrop.width;
		canvas.height = pixelCrop.height;
	} else {
		// console.log('maxSize ', maxSize)
		// console.log('pixelCrop.width ', pixelCrop.width * 2)
		// console.log('pixelCrop.height ', pixelCrop.height * 1.3)

		// dibedain
		if ( navigator.platform != "iPad" && navigator.platform != "iPhone" && navigator.platform != "iPod" ) {
			
			canvas.width = pixelCrop.width * 2
			canvas.height = pixelCrop.height * 1.3
		} else {
			// pakek sementara
			// canvas.width = (pixelCrop.width * 2) > maxSize ? (pixelCrop.width * 1.6) : (pixelCrop.width * 2) ;
			// canvas.height = (pixelCrop.height * 1.3) > maxSize ?  (pixelCrop.height ) : (pixelCrop.height * 1.3) ;

			canvas.width = (pixelCrop.width * 2) > maxSize ? (pixelCrop.width * 1.5) : (pixelCrop.width * 2) ;
			canvas.height = (pixelCrop.height * 1.3) > maxSize ?  (pixelCrop.height ) : (pixelCrop.height * 1.3) ;
		}
	}

	// paste generated rotate image with correct offsets for x,y crop values.
	ctx.putImageData(
		data,
		0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
		0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
	);

	// As Base64 string
	// return canvas.toDataURL("image/jpeg",0.7);
	return canvas;
}

export const generateDownload = async (imageSrc, crop, cekTw) => {
	if (!crop || !imageSrc) {
		return;
	}

	const canvas = await getCroppedImg(imageSrc, crop, cekTw);
	if (cekTw) {
		// localStorage.setItem("gambarUrlTw", canvas.toDataURL("image/jpeg",0.7));
		return canvas.toDataURL("image/png",0.7)
	} else {
		// localStorage.setItem("gambarUrl", canvas.toDataURL());
		return canvas
	}
};