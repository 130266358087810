import { combineReducers } from "redux";
import register from './register/index';
import gallery from './gallery/index';
import voucher from './voucher/index';

const rootReducer = combineReducers({
    register,
    gallery,
    voucher
});

export default rootReducer;
