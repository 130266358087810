import React, { useState,useEffect,useRef } from "react";
import { connect, useDispatch } from "react-redux";
import LogoHeader from "../../assets/img/register/header_logo_2.png"
import { history } from "../../utils";
import Gallery from '../../store/actions/gallery/index';
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import Sidebar from "../../components/layout/sidebar/index";


import Collage from "../../components/table";
import NavbarIcon from "../../assets/img/register/navbar-icon.png"
import Promo from '../../store/actions/promo/index.js'


const Index = ({match, action,data}) => {
    const [galeri, setGaleri] = useState([]);

    
    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(null);
    const [maxPage, setMaxPage] = useState(1);

    const [idBulanFilter, setIdBulanFilter] = useState("")
    const [sidebar, setSidebar] = useState(false);
    const handleClickToBack = () => {
        history.push('/')
    }
    const dispatch = useDispatch();
    useEffect(() => {
        let id = match?.params?.id
        handleRefresh(id);
        handleMonth();
    }, [page, idBulanFilter])

    const handleRefresh = (id) =>{    
        new Promise((resolve)=>{
            let param = {
                promotion_id: idBulanFilter ? idBulanFilter : id,
                page: page ? page : 1,
                length: 20
            }
            dispatch(Gallery.galleryPhoto(param,resolve, id))
        })
        .then(res => {
            let dataPhoto = []
                res?.data?.data.map((item) => {
                    dataPhoto = [...dataPhoto, { source: process.env.REACT_APP_API_URL+item.photo_url }]
                })
            setGaleri(dataPhoto)
            setTotalData(res?.data.records_total)
            setMaxPage(Math.ceil(res?.data.records_total / 20))
        })
    }

    const handleMonth = () => {
        new Promise((resolve)=>{
          let param = {
              page: 1,
              length: 999,
          }
          dispatch(Promo.promo(param,resolve))
        })
        .then(res => {
            let dataBulan = []
            res?.data.map((item) => {
                let namaBulan = ((item.name).split(' '))[0]
                dataBulan = [...dataBulan, { idBulan : item.id, namaBulan : namaBulan }]

                if(checkFirstMonth) {
                    if (match?.params?.id ==  item.id) {
                        setSelected({id: item.id, namaBulan: namaBulan})
                    }
                    setCheckFirstMonth(false)
                }
            })
            setValues(dataBulan)
        })
    }

    const [values, setValues] = React.useState([]);

    const [checkFirstMonth, setCheckFirstMonth] = useState(true)
    const [selected, setSelected] = useState({id:"", namaBulan:""});
    const handleSidebar = (value) => {
        if (value === false){
            console.log(value)
        }
       setSidebar(!sidebar)
    }
    const handleRef = () => {
        setSidebar(false)
    }
    return(
        <div className="section-detail-gallery">
              <Sidebar showSidebar={sidebar} close={handleRef}/>
            <div className="header-register">
                <img className="logo" src={LogoHeader}></img>
                <p><b>Galeri CPI</b></p>
                <img onClick={()=>handleSidebar(true)} className="nav-button" src={NavbarIcon}></img>
            </div>
            <div className="form-detail-gallery">
                {galeri ?
                <div className="card-detail-gallery">
                    <button className="btn-detail-gallery" onClick={handleClickToBack}>
                        <i className="fa fa-arrow-left"></i>
                    </button>
                    <p className="title-gallery" id="titleGallery"><b>Peserta Undian dengan Twibbon Promo CPI 2022</b></p>
                    <div className="row justify-content-end">
                        <div class="col-3 d-flex justify-content-end align-items-center tb-peserta-bulan">
                            Bulan
                        </div>
                        <div class="col-5">
                            <Select
                                className="dropdown-bulan"
                                value={selected.namaBulan}
                                onChange={(e, child) => 
                                    setSelected({id:child.props.id, namaBulan:child.props.value},
                                    setIdBulanFilter(values[child.props.id].idBulan)
                                )}
                                disableUnderline
                                style={{ width:'100%', fontSize:"12px", color:"#1C37BE", backgroundColor:"#DEEBFF"}}
                            >
                                {values.map((value, index) => {
                                    return <MenuItem value={value.namaBulan} id={index}>{value.namaBulan}</MenuItem>;
                                })}
                            </Select>
                        </div>
                    </div>
                    {galeri ? (
                        <Collage
                            data={galeri}
                            pagination={{
                                page,
                                totalData,
                                maxPage,
                                toggle: setPage
                            }}
                        />
                    ) : ""}
                </div> : "" }
                
            </div>
            
            
        </div>
    );
}

export default Index