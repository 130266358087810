// import { BehaviorSubject } from 'rxjs';
import { history } from "../../../utils/index";
import axios from "axios";
import { actionPending, actionSuccess, actionError } from "../action_types/index";

import { toastSuccess, toastError } from "../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_FAQ_PENDING = "GET_FAQ_PENDING";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_ERROR = "GET_FAQ_ERROR";

// URL: URL_{URL}
const FAQ_URL = `${process.env.REACT_APP_API_URL}faq`;


// const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user_clinic')));

const faq = (param, resolve, id) => (dispatch) => {
  dispatch(actionPending(GET_FAQ_PENDING));
  axios
    .post(FAQ_URL,param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_FAQ_SUCCESS, res));
    //   toastSuccess("Register Berhasil")
      if (resolve){
        let data = res.data.data.map((item, i) => ({
            ...item,
            active: false,
        }));
        resolve({
            data: res.data
        })
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_FAQ_ERROR));
      // toastError("Mengambil Data Galeri Gagal");
    });
};


const Faq = {
 faq,
};
export default Faq;
