import {
    GET_CHECK_VOUCHER_PENDING,
    GET_CHECK_VOUCHER_SUCCESS,
    GET_CHECK_VOUCHER_ERROR,
    POST_REDEEM_VOUCHER_PENDING,
    POST_REDEEM_VOUCHER_SUCCESS,
    POST_REDEEM_VOUCHER_ERROR,
    GET_AVAILABLE_VOUCHER_PENDING,
    GET_AVAILABLE_VOUCHER_SUCCESS,
    GET_AVAILABLE_VOUCHER_ERROR,
  } from "../../actions/voucher/index";
  
  // const user = JSON.parse(localStorage.getItem("user_clinic"));
  const initialState = {
    pending: false,
    error : null,
    data: null,
    redeem_pending: false,
    redeem_error: null,
    redeem_data: null,
  }
  const voucher = (state = initialState, action) => {
    switch (action.type) {
        case GET_CHECK_VOUCHER_PENDING:
          return {
            ...state,
            pending: true,
          };
        case GET_CHECK_VOUCHER_SUCCESS:
          return {
            ...state,
            pending: false,
            data: action
          };
        case GET_CHECK_VOUCHER_ERROR:
          return {
            ...state,
            pending: true,
            error: action.error
          };
          case POST_REDEEM_VOUCHER_PENDING:
          return {
            ...state,
            redeem_pending: true,
          };
        case POST_REDEEM_VOUCHER_SUCCESS:
          return {
            ...state,
            redeem_pending: false,
            redeem_data: action
          };
        case POST_REDEEM_VOUCHER_ERROR:
          return {
            ...state,
            redeem_pending: true,
            redeem_error: action.error
          };
          case GET_AVAILABLE_VOUCHER_PENDING:
          return {
            ...state,
            redeem_pending: true,
          };
        case GET_AVAILABLE_VOUCHER_SUCCESS:
          return {
            ...state,
            redeem_pending: false,
            redeem_data: action
          };
        case GET_AVAILABLE_VOUCHER_ERROR:
          return {
            ...state,
            redeem_pending: true,
            redeem_error: action.error
          };
      default:
        return state;
    }
  };
  
  export default voucher;
  