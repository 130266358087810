import React, { useState,useEffect,useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Splash from '../../components/splash/index';
import FormRegister from './FormRegister';
import LocationFailed from './LocationFailed';
import Checkpromo from '../../store/actions/register/index';
import PromoNotFound from './ExpiredPromotion';
import Slider from '../../components/slider/index';
import Loader from "../../components/commons/Loader";


const Index = ({action,data, match}) => {
    const [longlat, setLonglat] = useState([]);
    const [showRegister, setShowRegister] = useState(true);
    const [loadingSplash, setLoadingSplash] = useState(false);
    const [showSplash, setShowSplash] = useState(true);
    const [promo, setPromo] = useState("null");
    const [availPromo, setAvailPromo] = useState(false);
    const [urlTwibbon, setUrlTwibbon] = useState();
    const [kons, setKons] = useState("null");
    const dispatch = useDispatch();
    const ids = match?.params?.id 
   
    useEffect(() => {
       cekLokasi();
       handleCheckPromo();
       cekUserRegister();
    }, [])
    const handleCheckPromo = () => {
      let id = {code: ids};
      new Promise((resolve)=>{
          dispatch(Checkpromo.check_promo(resolve, id))
      })
      .then(res => {
          setPromo(res.data.message)
          setUrlTwibbon(res.data.data)
          if (res.data.message === "promo tersedia"){
            setAvailPromo(true)
          }else{
            setAvailPromo(false)
          }
          showLoadingSection()
      })
    }
    const cekUserRegister = () => {
      var user = JSON.parse(localStorage.getItem('userRegis'));
      var gambar = localStorage.getItem('gambarUrl');
      if (user || gambar){
        setShowSplash(false);
      }
    }

    // console.log(promo)
    function cekLokasi(){
      navigator.geolocation.getCurrentPosition(success, errors, options);
        //     setKons(""+console.log(navigator.permissions)+"")
        // if (navigator.permissions && navigator.permissions.query) {
        //     navigator.permissions
        //       .query({ name: "geolocation" })
        //       .then(function (result) {
        //         if (result.state === "granted") {
        //           //If granted then you can directly call your function here
        //           navigator.geolocation.getCurrentPosition(success);
        //           console.log("granted");
        //           setKons("granted")
        //         } else if (result.state === "prompt") {
        //           console.log("prompt")
        //           navigator.geolocation.getCurrentPosition(success, errors, options);
        //           setKons("propmt")
        //         } else if (result.state === "denied") {
        //           //If denied then you have to show instructions to enable location
        //           setShowRegister(false)
        //           setLoadingSplash(true);
        //           console.log("denied")
        //           setKons("denied")
        //         }
        //         result.onchange = function () {
        //           console.log(result.state);
        //         };
        //     });
           
        // } else if(navigator.geolocation) {
        //     console.log("elseif")
        //     // setShowRegister(false)
        //     navigator.geolocation.getCurrentPosition(success, errors, options);
        //     setKons(""+console.log(navigator.permissions)+"")
        //     setLoadingSplash(true);
        // }
      
    }

    var options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      };
    var allowGeoRecall = true
    function success(pos) {
      console.log(pos)
        var crd = pos.coords;
        
        var longlat = {longitude: crd.longitude, latitude: crd.latitude}
        setLonglat(longlat);
        setLoadingSplash(true);
    }
      
    function errors(err) {
      console.log(err)
        setLoadingSplash(true);
        setShowRegister(false);
    }

    
    const [showLoading, setShowLoading] = useState(true);
    const showLoadingSection = () => {
      setShowLoading(!showLoading)
    }

    if (showSplash){
      return(
        <div>
            {showLoading && <Loader />}
            {/* <Splash locations={loadingSplash} promos={promo}></Splash>  */}
            <Slider locations={loadingSplash} promos={promo} availPromos={availPromo}></Slider>
            {promo === 'promo tersedia' ? showRegister === true ? <FormRegister location={longlat} idpromo={ids} dataTwibbon={urlTwibbon}></FormRegister> : <LocationFailed></LocationFailed> : <PromoNotFound message={promo}></PromoNotFound>}
        </div>
    );
    }else{
      return(
        <div>
          <FormRegister location={longlat} idpromo={ids} dataTwibbon={urlTwibbon}></FormRegister>
        </div>
      )
    }
    
}

export default Index