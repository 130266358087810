import React, { useState,useEffect,useRef } from "react";
import { connect, useDispatch } from "react-redux";
import LogoHeader from "../../assets/img/register/header_logo_2.png"
import UploadIcon from "../../assets/img/register/image.png"
import { history } from "../../utils";
import 'font-awesome/css/font-awesome.min.css';
import Faq from '../../store/actions/faq/index';
// import Whatsapp from '../../components/contact/Index';
import NavbarIcon from "../../assets/img/register/navbar-icon.png"
import Sidebar from "../../components/layout/sidebar/index";

const Index = ({action}) => {
    const [data, setData] = useState();
    const [sidebar, setSidebar] = useState(false);
    const dispatch = useDispatch();
    const handleClickToBack = ()=>{
        history.goBack();
    }
    useEffect(() => {
        handleRefresh();
    }, [])
    const handleRefresh = () =>{
        
        new Promise((resolve)=>{
            let param = {
                page: 1,
                length: 999,
            }
            dispatch(Faq.faq(param,resolve))
        })
        .then(res => {
            setData(res?.data.data)
        })
    }
    const convertDate = (date) => {
        if (date != null) {
            let year = date.substring(0, 4)
            let month = date.substring(5, 7)
            let day = date.substring(8, 10)
            
            let listMonth = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
            month = listMonth[parseInt(month)-1]
    
            return `${day} ${month} ${year}`
        }
    }
    const handleClickToDetail = (id) =>{
        const updatedData = data.map(item=>{
            if (item.id == id){
                return {
                    ...item,
                    active: !item.active,
                }
            }else{
                return {
                    ...item,
                }
            }
        })
        setData(updatedData);

    }
    const handleSidebar = () => {
        setSidebar(!sidebar)
    }
    const handleRef = () => {
        setSidebar(false)
    }
    return(
        <div className="register" >
            <Sidebar showSidebar={sidebar} close={handleRef}/>
            <div className="header-register">
                <img className="logo" src={LogoHeader}></img>
                <p><b>FAQ</b></p>
                <img onClick={()=>handleSidebar()} className="nav-button" src={NavbarIcon}></img>
            </div>
            <div className="form-gallery">
                <button className="btn-detail-gallery" onClick={()=>handleClickToBack()}>
                        <i className="fa fa-arrow-left"></i>
                </button>
                <div>
                    <p>Berikut adalah pertanyaan yang sering ditanyakan terkait promo CPI</p>
                </div>
                {data ? (data.map((datax,index) => {
                    return (
                        <div className="card-faq" onClick={()=>handleClickToDetail(datax.id)} >
                        <div className="question">
                            <div className="title-question">
                                <p><b>{datax.title}</b></p>
                                <i className={datax.active ? "fa fa-caret-up" : "fa fa-caret-down"}></i>
                            </div>
                        </div>
                        <div className={datax.active ? "answer":"answer hide"}>
                            <div>
                                <p>
                                <div dangerouslySetInnerHTML={{ __html: datax.content }} />
                                </p>
                            </div>
                        </div>
                    </div>
                    )
                })) : ""
                }

                {/* <div className="card-faq">
                    <div className="question">
                        <div className="title-question">
                            <p><b>Apa Promo CPI Itu</b></p>
                            <i className="fa fa-caret-down"></i>
                        </div>
                    </div>
                    <div className="answer">
                        <div>
                            <p>Promo CPI adalah promo yang diselenggarakan oleh PT Charoen Pokphan Indonesia yang diadakan dan diundi setiap akhir bulan.</p>
                        </div>
                    </div>
                </div>
                */}
               
               
                
            </div>
            {/* <Whatsapp></Whatsapp> */}
        </div>
    );
}

export default Index