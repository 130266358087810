import { Route, Switch } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import Layout from "./components/layout";
import PrivateRoute from "./components/commons/privateRoute";
import EmptyState from "./views/emptyState";

import Index from "./views/Index";
import Splash from "./components/splash/index";
import Register from "./views/register/index";
import CropGambar from "./views/register/CropGambar";
import Twibbon from "./views/register/Twibbon";
import Gallery from "./views/gallery/index";
import DetailGallery from "./views/gallery/DetailGallery";
import ErrorLocation from './views/register/LocationFailed';
import SuccessRegistration from './views/register/SuccessRegister';
import FailedRegistration from './views/register/FailedRegister';
import ExpiredPromo from './views/register/ExpiredPromotion';
import Voucher from './views/voucher/Index';
import Faq from './views/faq/index';
import Privacy from './views/privacy/index';
import DetailTwibbonPesertaGallery from "./views/gallery/DetailTwibbonPeserta";
import StoreLocation from "./views/location/index";

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Switch>
        <PrivateRoute exact path="/promo/:date/:year/:id" component={Register} />
        <PrivateRoute exact path="/twibbon" component={Twibbon} />
        <PrivateRoute exact path="/crop-gambar" component={CropGambar} />
        <PrivateRoute exact path="/" component={Gallery} />
        <PrivateRoute exact path="/detail-gallery/:id" component={DetailGallery} />
        {/* <PrivateRoute path="*" component={EmptyState} /> */}
        <PrivateRoute exact path="/gagal" component={ErrorLocation}/>
        <PrivateRoute exact path="/sukses" component={SuccessRegistration}/>
        <PrivateRoute exact path="/failed" component={FailedRegistration}/>
        <PrivateRoute exact path="/expired" component={ExpiredPromo}/>
        <PrivateRoute exact path="/redeem/voucher/:id" component={Voucher}/>
        <PrivateRoute exact path="/faq" component={Faq}></PrivateRoute>
        <PrivateRoute exact path="/privacy-policy" component={Privacy}></PrivateRoute>
        <PrivateRoute exact path="/twibbon-peserta/:id" component={DetailTwibbonPesertaGallery}/>
        <PrivateRoute exact path="/lokasisemuatoko" component={StoreLocation} />

      </Switch>
    </>
  );
}

export default App;
