import {
    POST_REGISTER_PENDING,
    POST_REGISTER_SUCCESS,
    POST_REGISTER_ERROR,
    GET_CHECK_PROMO_PENDING,
    GET_CHECK_PROMO_SUCCESS,
    GET_CHECK_PROMO_ERROR,
    GET_CITY_PENDING,
    GET_CITY_SUCCESS,
    GET_CITY_ERROR,
    GET_JOB_ERROR,
    GET_JOB_PENDING,
    GET_JOB_SUCCESS,
  } from "../../actions/register/index";
  
  // const user = JSON.parse(localStorage.getItem("user_clinic"));
  const initialState = {
    pending: false,
    error : null,
    data: null,
  }
  const register = (state = initialState, action) => {
    switch (action.type) {
      case POST_REGISTER_PENDING:
        return {
          ...state,
          pending: true,
        };
      case POST_REGISTER_SUCCESS:
        return {
          ...state,
          pending: false,
          data: action.data
        };
      case POST_REGISTER_ERROR:
        return {
          ...state,
          pending: true,
          error: action.error
        };
        case GET_CHECK_PROMO_PENDING:
          return {
            ...state,
            pending: true,
          };
        case GET_CHECK_PROMO_SUCCESS:
          return {
            ...state,
            pending: false,
            data: action
          };
        case GET_CHECK_PROMO_ERROR:
          return {
            ...state,
            pending: true,
            error: action.error
          };
        case GET_CITY_PENDING:
          return {
            ...state,
            pending: true,
          };
        case GET_CITY_SUCCESS:
          return {
            ...state,
            pending: false,
            data: action
          };
        case GET_CITY_ERROR:
          return {
            ...state,
            pending: true,
            error: action.error
          };
          case GET_JOB_PENDING:
          return {
            ...state,
            pending: true,
          };
        case GET_JOB_SUCCESS:
          return {
            ...state,
            pending: false,
            data: action
          };
        case GET_JOB_ERROR:
          return {
            ...state,
            pending: true,
            error: action.error
          };
      default:
        return state;
    }
  };
  
  export default register;
  