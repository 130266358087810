import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import Sidebar from "./sidebar";

const Index = ({ children }) => {
  return (
   
       <div className="main">
            <div className="mobile-container">
              {children}
              </div>
        </div>
   
  );
};

const mapStateToProps = ({ masterDataPDAM }) => {
  return { masterDataPDAM };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
