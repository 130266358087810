import React, { useState,useEffect,useRef } from "react";
import { toastError, toastSuccess } from '../../components/commons/toast/index';
import * as Yup from 'yup';
import GambarSuccess from '../../assets/img/response/sukses.png';
import { history } from "../../utils/index";
import LogoHeader from "../../assets/img/landing-page/logo_biru_2.png"


const Index = () => {
const handleGallery = () =>{
        history.push("/")
}

    const [dataImg, setDataImg] = useState();
    useEffect(() => {
        refreshPage()
        setDataImg(GambarSuccess)
    }, [])

    const refreshPage = ()=>{
        const reloadCount = sessionStorage.getItem('reloadCount');
        if (reloadCount < 2) {
           sessionStorage.setItem('reloadCount', String(reloadCount + 1));
           window.location.reload();
        } else {
           sessionStorage.removeItem('reloadCount');
        }
    }

    return(
        <div className="content">
            <div className="header-logo">
                <img className="logo" src={LogoHeader}></img>
            </div>
           <div className="logo-response">
               <img className="gambar-success"src={dataImg ? dataImg : GambarSuccess}/>
           </div>
           <div className="caption-response">
                <h5><b>Pendaftaran Promo Undian Berhasil</b></h5>
                <p>Selamat Anda telah berhasil  mendaftar, harap menunggu pengumuman pemenang sampai periode promo undian telah berakhir</p>
           </div>
           <button onClick={(e)=>handleGallery()} className="button-kirim">
                Selesai
            </button>
        </div>
    );
}

export default Index