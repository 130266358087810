import React, { useState,useEffect,useRef } from "react";
import { Formik, Form } from "formik";
import Input from "../../components/forms/Input/index";
import File from "../../components/forms/File/index"
import LogoHeader from "../../assets/img/register/header_logo_2.png"
import UploadIcon from "../../assets/img/register/image.png"
import { toastError, toastSuccess } from '../../components/commons/toast/index';
import Splash from '../../components/splash/index';
import * as Yup from 'yup';
import html2canvas from "html2canvas";
import { history } from "../../utils/index";
import SuccessRegistration from "../../views/register/SuccessRegister";
import Loader from "../../components/commons/Loader";


const Index = ({props, dataX}) => {
    const [success, setSuccess] = useState(false);

    
    const [name, setName] = useState();
    const [image, setImage] = useState(null)
    const [imageBg, setImageBg] = useState(null)
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        handleRefresh();
    }, [])
    const handleRefresh = () =>{
        if (dataX){
            setName(dataX.name)
            setImage(dataX.gambar)
            setImageBg(dataX.bg)
        }
    }

    const exportAsPicture = () => {
        showLoadingSection()
        var data = document.getElementById('twibbon')
        html2canvas(data).then((canvas)=>{
        //   var image = canvas.toDataURL('image/png', 1.0);
         var url_blob = []
          var fileName = 'Twibbon.png'
          var image = canvas.toBlob(function(blob){
              var url = URL.createObjectURL(blob)
              saveAs(url, fileName)
          })
        })
    }

    const saveAs = (blob, fileName) =>{
        var elem = window.document.createElement('a');
        elem.href = blob
        elem.download = fileName;
        elem.style = 'display:none;';
        (document.body || document.documentElement).appendChild(elem);
        if (typeof elem.click === 'function') {
          elem.click();
        } else {
          elem.target = '_blank';
          elem.dispatchEvent(new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true
          }));
        }
        URL.revokeObjectURL(elem.href);
        elem.remove()
        setSuccess(true);
        localStorage.removeItem('gambarUrlTw');
        setTimeout(cekSukses, 4000);
    }

    const cekSukses = () => {
        history.push('/sukses')
    }

    const showLoadingSection = () => {
        setShowLoading(!showLoading)
    }

    // if (success){
        
    //     localStorage.removeItem('gambarUrlTw');
    //     return (
    //         <SuccessRegistration></SuccessRegistration>
    //     )
    // }else{
        return(
            <div className="form-register">
                {showLoading && <Loader />}
                <div>
                    <p>Silakan download twibbon foto Anda disini</p>
                </div>
                <div className="scale-twibbon" id="twibbon">
                    {/* ganti format */}
                    {imageBg && <img className="bg-twibbon" src={(imageBg)} />}
                    <div className="image-style">
                        {image && <img className="foto-twibbon" src={(image.preview)} />}
                    </div>
                </div>
                <button type="button" className="button-kirim"
                onClick={()=> exportAsPicture()}>
                    Download
                </button>
            </div>
       
            // <div className="register">
            //     <div className="header-register">
            //         <img className="logo" src={LogoHeader}></img>
            //         <p>Twibbon Foto</p>
            //     </div>
            //     <div className="form-register">
            //         <div>
            //             <p>Silakan download twibbon foto Anda disini</p>
            //         </div>
            //         <div className="scale-twibbon" id="twibbon">

            //             {/* <img className="bg-twibbon" src={(localStorage.getItem('gambarUrlTw'))} /> */}
                        
            //             {imageBg && <img className="bg-twibbon" src={(imageBg)} />}

            //             {/* <img className="bg-twibbon" src={(props.location.state.twGambar)} /> */}
            //             <div className="image-style">
            //                 {image && <img className="foto-twibbon" src={URL.createObjectURL(image.raw)} />}
                            
            //                 {/* <img className="foto-twibbon" src={(props.location.state.gambar)} /> */}
                            
            //                 <p>Halo, nama saya {name}</p>
            //                 {/* <p><b>Kami siap untuk mengikuti promo undian CPI</b></p> */}
            //             </div>

            //         </div>
            //         <button type="button" className="button-kirim"
            //         onClick={()=> exportAsPicture()}>
            //             Download
            //         </button>
            //     </div>
            // </div>
        );
    // }
}

export default Index