import React, { useState,useEffect } from "react";
import Register from "../../views/register/index"
import Logo from "../../assets/img/landing-page/landing page logo.png"
import { DisappearedLoading } from 'react-loadingg';

const Index = ({locations, promos})=> {
    const [ loading, setIsloading] = React.useState(true);
    const location = locations? locations : false;
    const promo = promos? true : false

    useEffect(() => {
        setIsloading(true);
        setTimeout( () => {
            setIsloading(false);
        },3000);
        
    }, [])
    return (
            <div className={loading === true || location === false || promo == false ? "splash": "splash panjang" }>
               <img className="landing-logo" src={Logo}></img>
               <div className="loading">
                    <DisappearedLoading color="#FFFFFF"></DisappearedLoading>
               </div>
            </div>
    )
}
export default Index;