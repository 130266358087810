import React, { useState,useEffect,useRef } from "react";
import icon from "../../assets/img/register/Vector.png"
import { history } from "../../utils/index";

const Index = ()=>{
    const no = "+6281216743730"
    const word = "Halo Admin CPI Promo"
    const link = "https://api.whatsapp.com/send?phone="+no+""+word+""
    const handleRedirect = () => {
        history.push('/faq')
    }
    return(
        <div  class="floating-faq" onClick={()=>handleRedirect()}>
           <img src={icon} className="icon"></img>
        </div>
    );
}
export default Index;