// import { BehaviorSubject } from 'rxjs';
import { history } from "../../../utils/index";
import axios from "axios";
import { actionPending, actionSuccess, actionError } from "../action_types/index";

import { toastSuccess, toastError } from "../../../components/commons/toast";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_LIST_STORE_PENDING = "GET_LIST_STORE_PENDING";
export const GET_LIST_STORE_SUCCESS = "GET_LIST_STORE_SUCCESS";
export const GET_LIST_STORE_ERROR = "GET_LIST_STORE_ERROR";
export const GET_TYPE_STORE_PENDING = "GET_TYPE_STORE_PENDING";
export const GET_TYPE_STORE_SUCCESS = "GET_TYPE_STORE_SUCCESS";
export const GET_TYPE_STORE_ERROR = "GET_TYPE_STORE_ERROR";

const LIST_STORE_URL = `${process.env.REACT_APP_API_URL}outlet`;
const TYPE_STORE_URL = `${process.env.REACT_APP_API_URL}outletType`;

const getListStore = (param, resolve, id) => (dispatch) => {
  dispatch(actionPending(GET_LIST_STORE_PENDING));
  axios
    .post(LIST_STORE_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_LIST_STORE_SUCCESS, res));
      if (resolve){
        let data;
        
        data = res.data
        resolve({
          data: data
        })
      }
    })
    .catch((err) => {
      resolve({
        data: {
          data: []
        },
        length: 0,
        page: 0,
        records_filtered: 0,
        records_total: 0
      })
      dispatch(actionError(GET_LIST_STORE_ERROR));
    });
};

const getTypeStore = (param, resolve, id) => (dispatch) => {
  dispatch(actionPending(GET_TYPE_STORE_PENDING));
  axios
    .post(TYPE_STORE_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_TYPE_STORE_SUCCESS, res));
      if (resolve){
        let data;
        
        data = res.data
        resolve({
          data: data
        })
      }
    })
    .catch((err) => {
      resolve({
        data: {
          data: []
        },
        length: 0,
        page: 0,
        records_filtered: 0,
        records_total: 0
      })
      dispatch(actionError(GET_TYPE_STORE_ERROR));
    });
};

const Store = {
  getListStore,
  getTypeStore
};
export default Store;
